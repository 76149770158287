<script>
import filtersMixin from "@/mixins/filtersMixin";
import User from "@/model/user";
export default {
  mixins: [filtersMixin],
  props: {
    layout: {
      required: true,
      default: () => []
    },
    permissionsList: {
      default: () => []
    },
    userDetails: {
      default: () => new User()
    },
    editable: {
      default: false
    }
  },
  data: function() {
    return {
      permissionsListCopy: this.$lodash.cloneDeep(this.permissionsList)
      // isIndeterminatePermissions: true,
      // isIndeterminateLocations: false,
      // checkAllLocations: false,
      // checkAllPermissions: false,
      // checkedPermissions: [],
      // checkedParsedPermissions: adminPermissionActionMap(false)
    };
  },
  watch: {
    permissionsList: function(val) {
      this.permissionsListCopy = this.$lodash.cloneDeep(this.permissionsList)
    }
  },
  computed: {
    adminPermissions() {
      return this.permissionsListCopy;
    },
    getSelectedUserType() {
      return this.userDetails.user_type;
    },
  },
  render(h) {
    return (
      <el-row class="page-access-component">
        {
          this.layout.map(item => {
          const mapItemToView = {
            permissions: this.editable
              ? this.getPermissionsEdit
              : this.getPermissionView,
            // locations: this.editable
            //   ? this.getLocationEdit
            //   : this.getLocationView
          };
          return mapItemToView[item](h);
        })}
      </el-row>
    );
  },
  // mounted() {
  //   this.handlePermissionsListCopy()
  // },
  methods: {
    // handlePermissionsListCopy() {
    //     this.userDetails.permissions.map( curr => {
    //       const index = this.permissionsListCopy.findIndex((obj) => obj.key === curr);
    //       if (index !== -1) {
    //         this.permissionsListCopy[index].value = true;
    //       }
    //     })
    // },
    // handlePermissionValueCheck(permission) {
    //   if (this.userDetails?.permissions.length > 0) {
    //     return this.userDetails.permissions.includes(permission.key);
    //   } else {
    //     return permission.value;
    //   }
    // },
    getPermissionView(h) {
      return (
        <el-row class="sub-user-permission-selection">
          <layout-toolbar justify="start" class="sub-user-details-header-superAdmin">
            <span class="material-icons-outlined color-variable">verified_user</span>
            <p class="sub-user-details-header__title color-variable">
              {this.ftm__capitalize('Permissions')}
            </p>
          </layout-toolbar>
          <el-row key={this.getSelectedUserType}>
            <el-col span={12} class="permissions-checkbox-container right-panel-content-alignment">
                {this.adminPermissions.map((permission, index) => {
                  const newPermission = permission;
                  const subTabItems = newPermission.subTabItems;
                  if (index <= 15) {
                    return (
                      <el-row class="permissions-checkbox-row">
                        <el-col span={20}>
                          <p class={subTabItems ? "permissions-checkbox-row__subTitle" : "permissions-checkbox-row__title"}>
                            {newPermission.name}
                          </p>
                        </el-col>
                        <el-col span={4}>
                          <el-switch disabled={true} value={newPermission.value}>
                          </el-switch>
                        </el-col>
                      </el-row>
                    );
                  }
                })}
            </el-col>
            <el-col span={12} class="permissions-checkbox-container right-panel-content-alignment">
                {this.adminPermissions.map((permission, index) => {
                  const newPermission = permission;
                  const subTabItems = newPermission.subTabItems;
                  if (index > 15) {
                    return (
                      <el-row class="permissions-checkbox-row">
                        <el-col span={20}>
                          <p class={subTabItems ? "permissions-checkbox-row__subTitle" : "permissions-checkbox-row__title"}>
                            {newPermission.name}
                          </p>
                        </el-col>
                        <el-col span={4}>
                          <el-switch disabled={true} value={newPermission.value}>
                          </el-switch>
                        </el-col>
                      </el-row>
                    );
                  }
                })}
            </el-col>
          </el-row>
        </el-row>
      );
    },
    getPermissionsEdit(h) {
      return (
        <el-row class="sub-user-permission-selection">
          <layout-toolbar justify="start" class="sub-user-details-header-superAdmin">
            <span class="material-icons-outlined color-variable">verified_user</span>
            <p class="sub-user-details-header__title color-variable">
              {this.ftm__capitalize('Permissions')}
            </p>
          </layout-toolbar>
          <el-row key={this.getSelectedUserType}>
            <el-col span={12} class="permissions-checkbox-container right-panel-content-alignment">
                {this.adminPermissions.map((permission, index) => {
                  const newPermission = permission;
                  const subTabItems = newPermission.subTabItems;
                  if (index <= 15) {
                    return (
                      <el-row class="permissions-checkbox-row">
                        <el-col span={20}>
                          <p class={subTabItems ? "permissions-checkbox-row__subTitle" : "permissions-checkbox-row__title"}>
                            {newPermission.name}
                          </p>
                        </el-col>
                        <el-col span={4}>
                          <el-switch disabled={newPermission.name === 'Help'} value={newPermission.name === 'Help' ? true : newPermission.value} onChange={$event => this.handlePermissionsChange($event, index)}
                          >
                          </el-switch>
                        </el-col>
                      </el-row>
                    );
                  }
                })}
            </el-col>
            <el-col span={12} class="permissions-checkbox-container right-panel-content-alignment">
                {this.adminPermissions.map((permission, index) => {
                  const newPermission = permission;
                  const subTabItems = newPermission.subTabItems;
                  if (index > 15) {
                    return (
                      <el-row class="permissions-checkbox-row">
                        <el-col span={20}>
                          <p class={subTabItems ? "permissions-checkbox-row__subTitle" : "permissions-checkbox-row__title"}>
                            {newPermission.name}
                          </p>
                        </el-col>
                        <el-col span={4}>
                          <el-switch value={newPermission.value} onChange={$event => this.handlePermissionsChange($event, index)}
                          >
                          </el-switch>
                        </el-col>
                      </el-row>
                    );
                  }
                })}
            </el-col>
          </el-row>
        </el-row>
      );
    },
    // handleInitLocationsChecks(value) {
    //   const checkedCount = value.length;
    //   this.checkAllLocations = checkedCount === this.locations.length;
    //   this.isIndeterminateLocations =
    //     checkedCount > 0 && checkedCount < this.locations.length;
    // },
    // handleCheckedLocationsChange: function(value) {
    //   this.handleInitLocationsChecks(value);
    //   this.$emit("locations-changed", value);
    // },
    // handleCheckAllLocations: function(value) {
    //   this.isIndeterminateLocations = false;
    //   this.checkAllLocations = value;
    //   this.$emit("locations-changed", value ? this.locationIds : []);
    // },
    // handleInitPermissionsChecks() {
    //   this.initCheckedParsedPermissions();
    //   this.initCheckedPermissions();
    //   this.checkAllPermissions = this.areAllPermissionsChecked();
    //   this.isIndeterminatePermissions = this.checkIndeterminatePermissions();
    // },
    // initCheckedParsedPermissions() {
    //   this.checkedParsedPermissions = this.userDetails.parsedPermissions;
    // },
    // initCheckedPermissions() {
    //   this.checkedPermissions = FTPermissions.reduce(
    //     (accPermissions, permissionKey) => {
    //       return FTPermissionsToActionsObj[permissionKey].reduce(
    //         (accPermissions2, actionKey) => {
    //           if (
    //             this.checkedParsedPermissions &&
    //             this.checkedParsedPermissions[permissionKey][actionKey]
    //           ) {
    //             accPermissions2.push(
    //               this.permissionsToBkPermissionsObj[permissionKey][actionKey]
    //             );
    //           }
    //           return accPermissions2;
    //         },
    //         accPermissions
    //       );
    //     },
    //     []
    //   );
    // },
    // handleCheckedPermissionsChange: function(value) {
    //   const currPermissions = [
    //     ...new Set(Object.values(this.checkedPermissionsNew).flat(2))
    //   ];
    //   this.$emit("selectedPermissions", currPermissions);
    // },
    // areAllPermissionsChecked() {
    //   return this.checkedPermissions.length === this.allPermissions.length;
    // },
    // checkIndeterminatePermissions() {
    //   return (
    //     this.checkedPermissions.length > 0 &&
    //     this.checkedPermissions.length < this.allPermissions.length
    //   );
    // },
    // handleCheckAllPermissions: function(value) {
    //   const checkedPermissions = value
    //     ? [...new Set(this.allPermissions.map(x => x.permissions).flat(1))]
    //     : [];
    //   this.isIndeterminatePermissions = false;
    //   this.checkAllPermissions = value;
    //   const newParsedPermissions = adminPermissionActionMap(value);
    //   this.$emit(
    //     "permissions-changed",
    //     checkedPermissions,
    //     newParsedPermissions
    //   );
    // },
    handlePermissionsChange: async function(value, index) {
      console.log("value--", value);
      const permissionsListCopyTemp = this.$lodash.cloneDeep(this.permissionsListCopy);
      this.permissionsListCopy = await permissionsListCopyTemp.reduce((acc, x) => {
        acc[index].value = value;
        return acc;
      }, this.permissionsListCopy);
      console.log("this.permissionsListCopy", this.permissionsListCopy);
      // this.makeValidParsedPermissions(newParsedPermissions, value);
      await this.$emit(
        "permissions-changed",
        this.permissionsListCopy
      );
    },
    // makeValidParsedPermissions(newParsedPermissions, permissionsList) {
    //   FTPermissions.reduce((accPermissions, permissionKey) => {
    //     return FTPermissionsToActionsObj[permissionKey].reduce(
    //       (accPermissions2, actionKey) => {
    //         if (actionKey === "VIEW") {
    //           return accPermissions2;
    //         }
    //         const canUserViewPreviously = this.checkedParsedPermissions[
    //           permissionKey
    //         ].VIEW;
    //         const canUserViewAtPresent = accPermissions2[permissionKey].VIEW;
    //         const canUserPerformCurrAction =
    //           accPermissions2[permissionKey][actionKey];
    //         if (
    //           [
    //             !canUserViewPreviously,
    //             !canUserViewAtPresent,
    //             canUserPerformCurrAction
    //           ].every(x => x)
    //         ) {
    //           accPermissions2[permissionKey].VIEW = true;
    //           permissionsList.push(
    //             this.permissionsToBkPermissionsObj[permissionKey].VIEW
    //           );
    //         }
    //         if (
    //           [
    //             canUserViewPreviously,
    //             !canUserViewAtPresent,
    //             canUserPerformCurrAction
    //           ].every(x => x)
    //         ) {
    //           accPermissions2[permissionKey][actionKey] = false;
    //           const indexOfPermission = this.getIndexOfPermissionInGivenList(
    //             permissionsList,
    //             this.permissionsToBkPermissionsObj[permissionKey][actionKey]
    //           );
    //           permissionsList.splice(indexOfPermission, 1);
    //         }
    //         return accPermissions2;
    //       },
    //       accPermissions
    //     );
    //   }, newParsedPermissions);
    // },
    // normalizeCheckBoxGroupArray(value) {
    //   return value.map(x => x.actionBkPermissions).flat(1);
    // },
    // getIndexOfPermissionInGivenList(permissionList, permissionToFind) {
    //   return permissionList.indexOf(permissionToFind);
    // },
  }
};
</script>

<style lang="scss" scoped>
.page-access-component {
  @include er-checkbox-checked(true);
  .sub-user-permission-selection + .sub-user-locations-selection {
    margin-top: 18px;
  }
  &::v-deep .el-checkbox__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .locations-item-container {
    flex-wrap: wrap;
    &::v-deep .el-checkbox-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .el-checkbox {
        @include responsiveProperty(width, 100px, 125px, 125px);
      }
    }
    .location-item:not(:first-child) {
      margin-left: 0px;
    }
    .location-item {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
      &__icon {
        margin-right: 10px;
        vertical-align: middle;
      }
      &__title {
        @include small-text;
      }
    }
  }

  .permissions-checkbox-container {
    display: flex;
    flex-direction: column;
    @include normal-text;
    .permissions-checkbox-row {
      display: flex;
      margin-bottom: 2px;
      margin-top: 2px;
      align-items: center;
      .permissions-checkbox-row__title {
        width: 200px;
        color: #6c7b8a;
      }
      .permissions-checkbox-row__subTitle {
        width: 200px;
        color: #6c7b8a;
        margin-left: 24px
      }
      p {
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
      }
      .el-checkbox {
        width: 80px;
      }
    }
  }
}
</style>
<style lang="scss">
.sub-user-details-header-superAdmin {
  padding: 12px 15px 0;
  font-weight: 700;
}
.color-variable{
  color: #0a2463;
}
</style>
