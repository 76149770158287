<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: Loader.vue
Description: This file contains the table component whose functionality is extended from the data-tables-server components. Functionalities includes searching, add button for creation of new items, column selections
-->
<!--
For Changing the table Columns values structure using slot with the name of the its label
* label name should be a spaced string
* slot name will be in lowercased, snake case
 -->

<template>
  <div class="settings-table">
    <div
      :class="{ 'padding-10-0': addPadingToSearch }"
      class="table-filters-right"
    >
      <layout-toolbar type="flex">
        <el-col :span="11">
          <slot name="table-header"> </slot>
        </el-col>

        <el-col :span="4">
          <el-input
            v-if="searchable"
            :placeholder="searchTranslationKeyName"
            :debounce="300"
            v-model="searchString"
            size="small"
            clearable
            @input="handleChange"
            @clear="handleClear"
            class="search-input"
            autofocus="true"
            ref="mainSearch"
          ></el-input>
        </el-col>
        <!-- <er-button
          class="btn-settings-table-refresh"
          @click="handleReload"
          size="small"
          type="default"
          :title="$t('refresh')"
          round
        >
          <i class="material-icons-round">refresh</i></er-button
        > -->
        <!-- <div v-if="group_settings" @click="handleOpenDialog">
          <span class="material-icons-outlined action-icon"> queue </span>
        </div> -->
        <er-button
          v-show="shouldRefreshVisible"
          @click="handleReload"
          class="er-btn-refresh"
          type="text"
          size="small"
        >
          <i class="material-icons-round">refresh</i>
        </er-button>
        <div v-if="columnselector_require">
          <er-column-selector
            key-to-store-defaults="customer-views-admin-table"
            keyToDecideDefaultCol="required"
            :title="`Column ${$t('Comn_visibility')}`"
            :columns="columns"
            @change-selection="handleChangeInColumnSelection"
          ></er-column-selector>
        </div>

        <slot name="table-toolbar-left-slot">
          <er-action-button
            v-if="button && userPermissionCheck"
            @action-btn-click="handleAddButtonClick()"
            :type="'solid'"
          >
            <template v-slot:icon>
              <i class="el-icon-circle-plus-outline"></i>
            </template>
            <template v-slot:label>
              <slot name="add-buttton"></slot>
            </template>
          </er-action-button>
        </slot>
      </layout-toolbar>
    </div>
    <el-scrollbar
      class="settings-table-scroll"
      viewClass="settings-table-scroll-view"
      wrapClass="settings-table-scroll-wrap"
    >
      <data-tables-server
        ref="dataTable"
        :data="tableData"
        :total="total"
        :size="size"
        :page-size="$constants.table['page-size']"
        :pagination-props="$constants.table['pagination-props']"
        :table-props="computedTableProps"
        @query-change="onQueryChange"
        @cell-click="handleCellClick"
        @expand-change="handleExpandChange"
        class="device-apis-table"
      >
        <template v-for="(column, index) in columns">
          <el-table-column
            :key="index"
            v-show="column.visible"
            :label="column.label"
            :sortable="column.sortable"
            :prop="column.prop"
            :min-width="column.minWidth"
            :fixed="column.fixed"
            v-if="
              selectedHeaders
                .map((sColumn) => sColumn.label)
                .includes(column.label)
            "
          >
            <template slot-scope="scope">
              <slot
                :name="
                  column.label
                    .toLowerCase()
                    .split(' ')
                    .join('_')
                "
                v-bind:row="{ data: scope.row, path: column.prop }"
              >
                <template
                  v-if="getRowValue(scope.row, column).type === 'array'"
                >
                  <el-tag
                    size="mini"
                    effect="dark"
                    v-for="(val, index) in getRowValue(scope.row, column).value"
                    :key="index"
                    >{{ val }}</el-tag
                  >
                </template>
                <template v-else>
                  {{ getRowValue(scope.row, column).value }}
                </template>
              </slot>
            </template>
            <slot
              :name="
                `${column.label
                  .toLowerCase()
                  .split(' ')
                  .join('_')}NestedColumn`
              "
            ></slot>
          </el-table-column>
        </template>
        <slot name="actionColumn">
          <el-table-column label="Actions" v-if="actionColumn">
            <er-button
              icon="el-icon-setting"
              size="small"
              :disabled="false"
            ></er-button>
          </el-table-column>
        </slot>
        <el-table-column label v-if="expandColumn" type="expand">
          <template slot-scope="scope">
            <slot
              name="expandColumn"
              v-bind:row="{
                data: scope.row,
                $index: scope.$index
              }"
            ></slot>
          </template>
        </el-table-column>
      </data-tables-server>
    </el-scrollbar>
  </div>
</template>

<script>
import dateUtils from '@/utils/dateUtils';
export default {
  props: {
    columns: {
      required: true,
      default: () => []
    },
    tableData: {
      required: true,
      default: () => []
    },
    total: {
      required: true,
      default: () => 0
    },
    size: {
      required: false,
      default: ''
    },
    addPadingToSearch: {
      required: false,
      default: () => true
    },
    actionColumn: {
      required: false,
      default: false
    },
    searchTag: {
      required: false,
      default: ''
    },
    searchProperty: {
      required: false,
      default: ''
    },
    height: {
      required: false,
      default: "100%"
    },
    tableProps: {
      default: () => ({})
    },
    expandColumn: {
      required: false,
      default: false
    },
    searchable: {
      required: false,
      default: () => true
    },
    button: {
      required: true,
      default: false
    },
    columnselector_require: {
      required: true,
      default: false
    },
    selectedColumnHeadersSortable: {
      require: false,
      default: false
    },
    shouldRefreshVisible: {
      required: false,
      default: () => true
    },
    group_settings: {
      require: false,
      default: false
    }
  },
  data: function() {
    return {
      selectedHeaders: this.columns.filter((column) => column.required),
      searchString: '',
      query: {},
      page: 1,
      limit: 30,
      debounce: null
    };
  },
  filters: {
    upper: function(value) {
      if (!value) return '';
      value = value.toString();
      return value.toLowerCase();
    }
  },
  computed: {
    allowCustomerTab() {
      return this.$store.getters["superadmin/getSuperAdminProfile"]?.permissions || [];
    },
    userPermissionCheck() {
      return this.$store.getters["superadmin/getSuperAdminProfile"]?.user_type === 'SUPER_ADMIN' ? this.allowCustomerTab.includes("CREATE_NEW_DEVICES_ALL") : true;
    },
    searchTranslationKeyName() {
      if (this.placeHolderForSearch && this.$t(this.placeHolderForSearch)) {
        return this.$t(this.placeHolderForSearch);
      }

      return this.$t('Comn_search');
    },
    computedTableProps: function() {
      return {
        height: this.height,
        headerCellClassName: 'settings-table-header-cell',
        ...this.tableProps
      };
    }
  },
  mounted() {
    if (this.searchable) {
      this.$refs.mainSearch.focus();
    }
    this.handlePaginationHideAndUnhide();
  },
  watch: {
    searchString: function(val) {
      this.searchString = val.toLowerCase();
    },
    total: function(val) {
      this.handlePaginationHideAndUnhide();
    }
  },
  methods: {
    handlePaginationHideAndUnhide() {
      const ele = document.getElementsByClassName('pagination-bar');
      if (this.total === 0) {
        ele.item(0).style.display = 'none';
      } else {
        ele.item(0).style.display = 'block';
      }
    },
    refreshTableLayout() {
      this.$nextTick(() => {
        if (this.$refs.dataTable) {
          this.$refs.dataTable.$refs.elTable.doLayout();
        }
      });
    },
    getRowValue(object, columnObject) {
      const path = columnObject.prop;
      const type = columnObject.type;
      const value =
        typeof this.$lodash.result(object, path) === 'undefined' ||
        this.$lodash.result(object, path) === '' ||
        this.$lodash.result(object, path) === null
          ? '--'
          : this.$lodash.result(object, path);

      if (type === 'date') {
        return value && value !== '--'
          ? {
              type: 'string',
              value: dateUtils.formatDate(
                new Date(value),
                this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_TIME
              )
            }
          : { type: 'string', value: '--' };
      }
      if (Array.isArray(value)) {
        return { type: 'array', value };
      }
      return { type: 'string', value };
    },
    onQueryChange(queryInfo) {
      switch (queryInfo.type) {
        case 'sort':
          this.sort(queryInfo.sort.prop, queryInfo.sort.order);
          break;
        case 'size':
        case 'page':
          this.pagination(queryInfo.page, queryInfo.pageSize);
          break;
        case 'init':
          this.query.page = 1;
          this.query.limit = this.$constants.table['page-size'];
          break;
        default:
          throw new Error(
            'Not implemented onquerychange for ' + queryInfo.type
          );
      }
      this.$emit('loadData', this.query);
    },
    sort(property, orderType) {
      this.query.order_by = property;
      if (orderType === 'descending') {
        this.query.order_type = 'desc';
      } else if (orderType === 'ascending') {
        this.query.order_type = 'asc';
      } else if (orderType === null) {
        delete this.query.order_type;
        delete this.query.order_by;
      }
    },
    pagination(page, pageSize) {
      this.query.limit = pageSize;
      this.query.page = page;
      this.page = page;
      this.limit = pageSize;
      // this.applyFilters({ value: pageSize, type: "limit" });
      // this.applyFilters({ value: page, type: "page" });
    },
    async handleReload() {
      const options = {
        page: this.page,
        limit: this.limit
      };
      if (this.searchString) {
        options.user_name = this.searchString;
        options.device_code = this.searchString;
      }
      await this.$emit('reload', options);
    },
    handleChange(string) {
      if (string === '') {
        console.log(string);
        this.query.page = this.page;
        this.query.limit = this.limit;
        delete this.query[this.searchProperty];
        this.$emit('loadData', this.query);
      } else {
        this.query[this.searchProperty] = string.trim();
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.typing = null;
          this.$emit('loadData', this.query);
        }, 1000);
      }
    },
    handleCellClick(row, column, cell, event) {
      this.$emit('cell-click', row, column, cell, event);
    },
    async handleClear() {
      await this.$emit('cleared', {});
    },
    handleAddButtonClick() {
      this.$emit('add-item-click');
    },
    handleExpandChange(row) {
      this.$emit('expand-change', row);
    },
    handleChangeInColumnSelection(selectedColumns) {
      this.selectedHeaders = selectedColumns.map((x) => this.columns[x]);
      this.refreshTableLayout();
    },
    // handleOpenDialog() {
    //   this.$emit("add-group-settings-click");
    // },
  }
};
</script>

<style lang="scss">
.settings-table {
  .el-table {
    // overflow: unset;
    border-radius: 7px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    max-width: 100%;
    font-size: $app_font_size_1;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_2,
    //   $app_font_size_3,
    //   $app_font_size_xl
    // );
    color: #606266;
    // .el-table__body-wrapper {
    //       display: flex;
    //       flex-direction: column;
    //       min-height: 0;
    //       height: 100% !important;
    //       overflow-y: auto;
    //     }
  }

  .settings-table-scroll {
    position: relative;
    @include responsiveProperty(height, 75vh, 79vh, 83vh);
  }
  .settings-table-scroll-view {
    padding-right: 10px;
  }
  .settings-table-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }
  .padding-10-0 {
    padding: 10px 0;
  }
  .device-apis-table > .el-table {
    font-size: $app_font_size_1;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_2,
    //   $app_font_size_3,
    //   $app_font_size_xl
    // );
    // font-size: 12px !important;
  }
  .el-select {
    width: 100%;
  }
  .device-apis-table > .pagination-bar {
    margin-top: 10px;
  }

  .el-table__row {
    td:first-child {
      cursor: pointer;
    }
  }
  div.cell .el-link.el-link--default {
    font-size: $app_font_size_1;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_2,
    //   $app_font_size_3,
    //   $app_font_size_xl
    // );
    // letter-spacing: 0.9px;
    font-weight: 100;
  }
  .settings-table-header-cell {
    color: #333;
    font-weight: 500;
  }
  .btn-settings-table-refresh {
    padding: 8px 12px;
  }
  .search-input .el-input__inner {
    text-transform: uppercase;
  }
  .search-input .el-input__inner::-webkit-input-placeholder {
    text-transform: initial;
  }
  .search-input .el-input__inner:-moz-placeholder {
    text-transform: initial;
  }
  .search-input .el-input__inner::-moz-placeholder {
    text-transform: initial;
  }
  .search-input .el-input__inner:-ms-input-placeholder {
    text-transform: initial;
  }
  .el-input--mini .el-input__inner {
    height: 23px;
    line-height: 23px;
  }
  .sc-table .pagination-bar {
    margin-top: 10px;
  }
  .el-table td,
  .el-table th {
    padding: 6px 0px;
    border-bottom: 1.1px solid !important;
    border-bottom-color: #ebeef5 !important;
  }

  .el-button--text {
    padding: 0px;
  }
  .el-table .cell {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .el-table thead.is-group th {
    background-color: #ffffffff;
    font-weight: bold;
  }
  .el-table--border,
  .el-table--group {
    border: none;
  }
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    background-color: #ffffffff;
  }

  .el-table th {
    font-weight: bold;
    color: #233a73;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }

  .el-link.el-link--default {
    font-size: 11px;
    font-weight: 100;
  }
  .el-table th > .cell {
    color: #233a73;
    line-height: 120%;
  }

  .el-link--inner {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .er-button.el-button--text i {
    color: #000000;
    @include responsiveProperty(font-size, 23px, 28px, 32px);
  }
  .action-icon {
    cursor: pointer;
    color: black;
    opacity: 0.3;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    @include icon-size(icon-font);
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}
</style>
