/* eslint-disable comma-dangle */
<template>
  <div>
    <dialog-form
      :model="getNewShrimpTalk()"
      :schema="shrimpTalkFields"
      :showDialog="dialogVisible"
      :loading="dialogBoxLoading"
      title="Add ShrimpTalk"
      :backendValidations="createSTFormErrors"
      @close-dialog="closeDialog"
      @save-details="handleSaveNewShrimpTalk"
      @clear-validations="handleClearValidations"
      deviceType="SHRIMP_TALK"
      :shouldErrorHighlightShow="true"
    ></dialog-form>
    <settings-table
      ref="erDataTables"
      :tableProps="elTableProps"
      v-loading="loading"
      element-loading-background="white"
      :tableData="tableData"
      :columns="columnsObject"
      :total="total"
      :addPadingToSearch="true"
      :button="true"
      :group_settings="true"
      :columnselector_require="true"
      :selectedColumnHeadersSortable="true"
      search-property="device_code"
      placeHolderForSearch="Search_device_code"
      @cell-click="handleCellClick"
      @reload="handleLoadData"
      @loadData="handleLoadData"
      @cleared="handleLoadData"
      @add-item-click="handleAddClick"
      class="is-filters-set-absolute"
    >
      <!-- @add-group-settings-click="handlegroupSettingsClick" -->
      <template slot="add-buttton"> Add ShrimpTalk </template>
      <template v-slot:shrimptalk_id="{ row }">
        <router-link :to="`/admin/shrimptalks/${row.data._id}`">
          {{ stIdString + row.data.v1_code }}
        </router-link>
      </template>
      <template v-slot:v1_id="{ row }">
        {{ row.data.v1_id }}
        {{ row.data.v1_code ? ` (${row.data.v1_code})` : "" }}
      </template>
      <template v-slot:pondmothers="{ row }">
        <template v-if="row.data.pond_mothers.length > 0">
          <!-- <el-tag
            size="mini"
            :effect="feeder.is_master ? 'dark' : 'light'"
            v-for="(feeder, index) in row.data.pond_mothers"
            :key="index"
            :title="feeder.is_master ? 'master' : 'non master'"
            :class="feeder.is_master ? 'dark-tag' : 'light-tag'"
          >
            <router-link
              :to="`/admin/pondmothers/${feeder._id}`"
              style="color: white"
              >{{ feeder.code }}</router-link>
          </el-tag> -->
          <router-link
            :to="`/admin/pondmothers/${feeder._id}`"
            style="color: #ffffff"
            :effect="feeder.is_master ? 'dark' : 'light'"
            v-for="(feeder, index) in row.data.pond_mothers"
            :key="index"
            :title="feeder.is_master ? 'master' : 'non master'"
            :class="feeder.is_master ? 'dark-tag' : 'light-tag'"
          >
            {{ feeder.code }}
          </router-link>
        </template>
      </template>
      <template v-slot:v2_parsing_enabled="{ row }">
        <el-switch
          :value="
            row.data.settings.v2_parsing ? row.data.settings.v2_parsing : false
          "
          @input="handleASettingKeyChange($event, row.data, 'v2_parsing')"
        ></el-switch>
      </template>
      <template v-slot:v1_parsing_enabled="{ row }">
        <el-switch
          :value="
            row.data.settings.v1_parsing ? row.data.settings.v1_parsing : false
          "
          @input="handleASettingKeyChange($event, row.data, 'v1_parsing')"
        ></el-switch>
      </template>
      <template v-slot:feeding_model="{ row }">
        {{ getFeedingModelVal(row.data.settings.feeding_model) }}
      </template>
      <template v-slot:pm_gateway_codes="{ row }">
        <template
          v-if="getArrayOfFeederGatewayID(row.data.settings).length > 0"
        >
          <el-tag
            v-for="(feeder, index) in getArrayOfFeederGatewayID(
              row.data.settings
            )"
            :key="index"
            :title="index === 0 ? 'feeder 1' : 'feeder 2'"
            :class="index === 0 ? 'dark-tag1' : 'light-tag1'"
          >
            {{ feeder }}
          </el-tag>
        </template>
        <template v-else>--</template>
      </template>
      <template v-slot:pm_gateway_ips="{ row }">
        <template
          v-if="getArrayOfFeederGatewayID(row.data.settings).length > 0"
        >
          <el-tag
            size="mini"
            effect="dark"
            v-for="(feeder, index) in getArrayOfFeederGatewayIP(
              row.data.settings
            )"
            :key="index"
            :title="index === 0 ? 'feeder 1' : 'feeder 2'"
            >{{ feeder }}</el-tag
          >
        </template>
        <template v-else>--</template>
      </template>
      <template v-slot:pm_gateway_channels="{ row }">
        <template
          v-if="getArrayOfFeederGatewayChannel(row.data.settings).length > 0"
        >
          <el-tag
            size="mini"
            effect="dark"
            v-for="(feeder, index) in getArrayOfFeederGatewayChannel(
              row.data.settings
            )"
            :key="index"
            :title="index === 0 ? 'feeder 1' : 'feeder 2'"
            :class="index === 0 ? 'dark-tag2' : 'light-tag2'"
            >{{ feeder }}</el-tag
          >

          <br />
        </template>
        <template v-else>--</template>
      </template>
      <template v-slot:pondguard_enabled="{ row }">
        <el-switch
          :value="
            row.data.settings.pg_enable ? row.data.settings.pg_enable : false
          "
          @input="handleASettingKeyChange($event, row.data, 'pg_enable')"
        ></el-switch>
      </template>
      <template v-slot:installed_date="{ row }">{{
        ftm__formatEmpty(adm__filterToFormatDate(row.data.on_boarded_at))
      }}</template>
      <template v-slot:module_name="{ row }">{{
        ftm__formatEmpty(row.data.settings.module_name)
      }}</template>
      <template v-slot:module_size="{ row }">{{
        ftm__formatEmpty(row.data.settings.module_size)
      }}</template>
      <template v-slot:user_details="{ row }">
        {{
          ftm__objectValueByPath(
            row.data.user_details,
            "full_name",
            ftm__formatEmpty("--")
          )
        }}
      </template>
      <template v-slot:last_communication="{ row }">
        <el-tag
          size="mini"
          type="success"
          effect="dark"
          v-if="row.data.utc_last_communicated_at_status"
        >
          {{
            ftm__formatEmpty(
              adm__filterToFormatDate(row.data.utc_last_communicated_at)
            )
          }}
        </el-tag>
        <template v-else>
          {{
            ftm__formatEmpty(
              adm__filterToFormatDate(row.data.utc_last_communicated_at)
            )
          }}
        </template>
      </template>
      <template v-slot:time_zone_details="{ row }">
        {{ getTimeZoneData(row.data.user_details) }}
      </template>
      <template v-slot:block_st="{ row }">
          <el-switch
            disabled
            :value="row.data.block_shrimp_talk"
          ></el-switch>
            <!-- @input="handleEnableFeature($event, row.data)" -->
        </template>
    </settings-table>
    <!-- <component
      :is="`shrimpTalkGroupSettingsDialog`"
      :showDialog="group_setting_dialogVisible"
      @close_dialog="closeGroupSettingsDialog"
    ></component> -->
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
import filtersMixin from "@/mixins/filtersMixin";
import settingsTable from "@/components/base/settingsTable";
import dialogForm from "@/components/superadmin/shared/dialogForm";
import { mapActions } from "vuex";
// import shrimpTalkGroupSettingsDialog from "./shrimpTalkGroupSettingsDialog.vue"
export default {
  mixins: [
    errorHandlerMixin,
    adminDatesHandlerMixin,
    notificationMixin,
    filtersMixin
  ],
  components: {
    settingsTable,
    dialogForm,
    // shrimpTalkGroupSettingsDialog
  },
  data() {
    return {
      dialogVisible: false,
      // group_setting_dialogVisible: false,
      createSTFormErrors: {},
      loading: false,
      tableData: [],
      total: 0,
      dialogBoxLoading: false,
      stIdString: "ST"
    };
  },
  computed: {
    columnsObject: function () {
      return [
        {
          prop: "v1_code",
          label: "ShrimpTalk Id",
          required: true,
          minWidth: 150,
          fixed: "left"
        },
        {
          prop: "code",
          label: "Hex Id",
          required: true,
          sortable: true,
          minWidth: 100
        },
        {
          prop: "user_details.full_name",
          label: "Customer Name",
          required: true,
          minWidth: 200
        },
        {
          prop: "user_details.timezone.name",
          label: "Customer Timezone",
          required: true,
          minWidth: 200,
        },
        {
          prop: "pond_details.title",
          label: "Pond Name",
          required: true,
          minWidth: 200,
        },
        {
          prop: "dealer_details.full_name",
          label: "Dealer Name",
          required: true,
          minWidth: 200,
        },
        {
          prop: "location_details.name",
          label: "Location",
          required: true,
          minWidth: 200,
        },
        {
          label: "Alias",
          prop: "title",
          required: true,
          minWidth: 200,
        },
        {
          prop: "settings.software_version",
          label: "Software Version",
          required: true,
          minWidth: 100
        },
        {
          label: "Block ST",
          prop: "block_shrimp_talk",
          required: false,
          minWidth: 100
        },
        {
          prop: "utc_last_communicated_at",
          label: "Last Communication",
          required: true,
          sortable: "custom",
          type: "date",
          minWidth: 150
        },
        {
          prop: "settings.ip_address",
          label: "IP Address",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.feeders",
          label: "PondMothers",
          required: true,
          minWidth: 200
        },
        {
          prop: "settings.feeder1_gateway_id",
          label: "PM Gateway codes",
          required: true,
          minWidth: 200
        },
        // {
        //   prop: "location_name",
        //   label: "Location",
        //   required: true,
        //   minWidth: 120
        // },
        {
          prop: "pond_details.title",
          label: "Pond",
          required: true,
          minWidth: 120
        },
        {
          prop: "v1_id",
          label: "V1 Id",
          required: true,
          minWidth: 150
        },

        {
          prop: "_id",
          label: "V2 Id",
          required: true,
          minWidth: 200
        },
        {
          prop: "settings.feed_limit",
          label: "Feed Limit",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.feeding_model",
          label: "Feeding Model",
          required: true,
          minWidth: 100,
        },

        {
          prop: "settings.settings_interval",
          label: "Settings Interval",
          required: false,
          minWidth: 100
        },

        {
          prop: "settings.reset_index",
          label: "Reset Index",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.start_index",
          label: "Start Index",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.service_restart",
          label: "Service Restart",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.lora_channel",
          label: "Lora Channel",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.wifi_networks",
          label: "Wifi Networks",
          required: false,
          minWidth: 100
        },
        {
          prop: "sensor_values.wifi_signal_RSSI",
          label: "Signal Strength",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.wifi_passwords",
          label: "Wifi Passwords",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.wifi_security",
          label: "Wifi Security",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.mac_address",
          label: "MAC Address",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.os_version",
          label: "OS Version",
          required: false,
          minWidth: 100
        },

        {
          prop: "settings.hardware_version",
          label: "Hardware Version",
          required: true,
          minWidth: 100
        },

        {
          prop: "settings.feeder1_gateway_ip",
          label: "PM Gateway IPs",
          required: false,
          minWidth: 200
        },
        {
          prop: "settings.feeder1_gateway_channel",
          label: "PM Gateway Channels",
          required: true,
          minWidth: 200
        },
        {
          prop: "settings.pi_reboot",
          label: "Pi Reboot",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.v1_parsing",
          label: "V1 Parsing Enabled",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.v2_parsing",
          label: "V2 Parsing Enabled",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.pg_enable",
          label: "PondGuard Enabled",
          required: false,
          minWidth: 100
        },

        // {
        //   prop: "user_details.timezone.name",
        //   label: "Time Zone Details",
        //   required: true,
        //   minWidth: 150,
        // },
        {
          prop: "user_details.timezone.name",
          label: "Time Zone Details",
          required: true,
          minWidth: 150
        },
        {
        prop: "settings.timezone",
        label: "Device Timezone",
        required: true,
        minWidth: 150
      },
      {
        field: "settings.module_name",
        label: "Module Name",
        required: true,
        minWidth: 150
      },
      {
        field: "settings.module_size",
        label: "Module Size",
        required: true,
        minWidth: 150
      },
        {
          prop: "on_boarded_at",
          label: "Installed date",
          required: false,
          sortable: true,
          type: "date",
          minWidth: 150
        }
      ];
    },
    getFeedingModelVal: function () {
      return (val) => {
        if (val === 0) {
          return "Energy";
        } else if (val === 1) {
          return "Signature";
        } else {
          return "--";
        }
      };
    },
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      if (window.innerWidth < 2560 && window.innerWidth > 1537) {
        return "calc(100vh - 210px)";
      } else if (window.innerWidth > 1535 && window.innerWidth < 1920) {
        return "calc(100vh - 200px)";
      } else {
        return "calc(100vh - 150px)";
      }
    },
    shrimpTalkFields() {
      return [
        {
          type: "datePicker",
          inputType: "string",
          label: "Installation Date",
          model: "on_boarded_at",
          validationRules: "required"
        }
      ];
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      fetchAllUsers: "fetchAllUsers",
      fetchADeviceData: "fetchADeviceData",
      clearDeviceDetails: "clearDeviceDetails",
      saveListDevicesData: "saveListDevicesData",
      updateASettingKey: "updateASettingKey",
      updateBlockStStatus: "updateBlockStStatus"
    }),
    getTimeZoneData(data) {
      if (data && data.hasOwnProperty("timezone")) {
        return this.ftm__formatEmpty(data.timezone.name);
      } else {
        return "--";
      }
    },
    getArrayOfFeederGatewayID(settings) {
      const arr = [];
      if (settings) {
        if (
          Array.isArray(settings.feeder1_gateway_id) &&
          settings.feeder1_gateway_id.length
        ) {
          arr.push(...settings.feeder1_gateway_id);
        } else {
          if (settings.feeder1_gateway_id) {
            arr.push(settings.feeder1_gateway_id);
          }
        }
        if (
          Array.isArray(settings.feeder2_gateway_id) &&
          settings.feeder2_gateway_id.length
        ) {
          arr.push(...settings.feeder2_gateway_id);
        } else {
          if (settings.feeder2_gateway_id) {
            arr.push(settings.feeder2_gateway_id);
          }
        }
      }
      return arr;
    },
    getArrayOfFeederGatewayIP(settings) {
      const arr = [];
      if (settings) {
        if (
          Array.isArray(settings.feeder1_gateway_ip) &&
          settings.feeder1_gateway_ip.length
        ) {
          arr.push(...settings.feeder1_gateway_ip);
        } else {
          if (settings.feeder1_gateway_ip) {
            arr.push(settings.feeder1_gateway_ip);
          }
        }
        if (
          Array.isArray(settings.feeder2_gateway_ip) &&
          settings.feeder2_gateway_ip.length
        ) {
          arr.push(...settings.feeder2_gateway_ip);
        } else {
          if (settings.feeder2_gateway_ip) {
            arr.push(settings.feeder2_gateway_ip);
          }
        }
      }
      return arr;
    },
    getArrayOfFeederGatewayChannel(settings) {
      const arr = [];
      if (settings) {
        if (
          Array.isArray(settings.feeder1_gateway_channel) &&
          settings.feeder1_gateway_channel.length
        ) {
          arr.push(...settings.feeder1_gateway_channel);
        } else {
          if (settings.feeder1_gateway_channel) {
            arr.push(settings.feeder1_gateway_channel);
          }
        }
        if (
          Array.isArray(settings.feeder2_gateway_channel) &&
          settings.feeder2_gateway_channel.length
        ) {
          arr.push(...settings.feeder2_gateway_channel);
        } else {
          if (settings.feeder2_gateway_channel) {
            arr.push(settings.feeder2_gateway_channel);
          }
        }
      }
      return arr;
    },
    handleAddClick() {
      this.dialogVisible = true;
    },
    // handlegroupSettingsClick() {
    //   this.group_setting_dialogVisible = true;
    // },
    handleCellClick(row, column, cell, event) {
      if (column.label === this.columnsObject[0].label) {
        this.clearDeviceDetails({
          device_type: "shrimp_talk"
        });
        // this.$router.push({
        //   name: "ShrimpTalkDetails",
        //   params: { st_id: row._id }
        // });
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        if (
          query.device_code &&
          query.device_code.toUpperCase().startsWith("ST")
        ) {
          query.device_code = query.device_code.substr(2).trim();
        }
        const response = await this.fetchAllDeviceDetails({
          device_type: "shrimp_talk",
          infinite_scroll: false,
          params: query
        });
        this.tableData = this.$store.getters["superadmin/getShrimpTalks"];
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.erDataTables) {
            this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    async handleASettingKeyChange(value, shrimptalk, settingKey) {
      const confirmMessage = {
        v1_parsing: {
          warning: `Your are ${
            value ? "enabling" : "disabling"
          } v1 parsing for ST${shrimptalk.v1_code}, Are you sure?`,
          success: `V1 parsing is ${value ? "enabled" : "disabled"} for ST${
            shrimptalk.v1_code
          }`
        },
        v2_parsing: {
          warning: `Your are ${
            value ? "enabling" : "disabling"
          } v2 parsing for ST${shrimptalk.v1_code}, Are you sure?`,
          success: `V2 parsing is ${value ? "enabled" : "disabled"} for ST${
            shrimptalk.v1_code
          }`
        },
        pg_enable: {
          warning: `Your are ${
            value ? "enabling" : "disabling"
          } the pg details for ST${shrimptalk.v1_code}, Are you sure?`,
          success: `Pg Details are ${value ? "enabled" : "disabled"} for ST${
            shrimptalk.v1_code
          }`
        }
      };
      const payload = {
        code: shrimptalk.code,
        [settingKey]: value
      };
      try {
        await this.$confirm(confirmMessage[settingKey].warning, "Warning", {
          confirmButtonText: "ok",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        });
        this.loading = true;
        await this.updateASettingKey({
          device_type: "shrimp_talk",
          setting_key: settingKey,
          payload
        });
        this.$notify({
          type: "success",
          title: "Success",
          message: confirmMessage[settingKey].success
        });
        await this.handleLoadData(this.queryParams);
      } catch (error) {
        if (error !== "cancel") {
          const errorCode = error.response.data.status_code;
          let message;
          switch (errorCode) {
            case 409:
              message = error.response.data.message;
              this.$notify({
                type: "error",
                title: "Failed",
                message
              });
              break;
            default:
              this.ehm__errorMessages(error, true);
          }
        }
      } finally {
        this.loading = false;
      }
    },
    handleClearValidations(type, indexPosition) {
      switch (type) {
        case "ALL":
          this.createSTFormErrors = {};
          break;
        case "SINGLE":
          delete this.createSTFormErrors[indexPosition];
          break;
      }
    },
    getNewShrimpTalk() {
      return {
        on_boarded_at: this.adm__dateUtilsLib.formatDate(
          new Date(),
          "yyyy-MM-dd"
        )
      };
    },
    closeDialog() {
      this.createSTFormErrors = {};
      this.dialogVisible = false;
    },
    // closeGroupSettingsDialog() {
    //   this.group_setting_dialogVisible = false;
    //   this.handleLoadData(this.queryParams);
    // },
    async handleSaveNewShrimpTalk(model, arrNames) {
      try {
        this.dialogBoxLoading = true;

        const payload = arrNames.map((name) => {
          return {
            v1_code: name,
            on_boarded_at: model.on_boarded_at
          };
        });

        await this.saveListDevicesData({
          device_type: "SHRIMP_TALK",
          payload
        });
        this.$notify({
          title: "Success",
          message: "Created ShrimpTalk Successfully",
          type: "success"
        });
        this.dialogVisible = false;
      } catch (err) {
        this.$notify({
          title: "Failed",
          message: "Created ShrimpTalk UnSuccessful",
          type: "error"
        });
        if (err.response && err.response.data) {
          this.createSTFormErrors = err.response.data.errors.details.reduce(
            (acc, err) => {
              acc[err.key] = err.message;
              return acc;
            },
            {}
          );
        }
      } finally {
        this.dialogBoxLoading = false;
      }
    },
    // async handleEnableFeature(value, userDetails) {
    //   try {
    //     const confirmMessage = `Your are ${
    //         value ? "blocking" : "unblocking"
    //       } the ShrimpTalk, Are you sure?`;
    //     await this.$confirm(confirmMessage, "Warning", {
    //       confirmButtonText: "ok",
    //       cancelButtonText: this.$t("Comn_cancel"),
    //       type: "warning"
    //     });
    //     this.loading = true;
    //     const payload = {
    //       shrimp_talk_ids: [userDetails._id],
    //       block_shrimp_talk: value
    //     }
    //     await this.updateBlockStStatus(payload);
    //     this.$notify({
    //       type: "success",
    //       title: "Success",
    //       message: value ? "ShrimpTalk Blocked" : "ShrimpTalk Unblocked"
    //     });
    //     await this.handleLoadData(this.queryParams);
    //   } catch (error) {
    //     if (error !== "cancel") {
    //       const errorCode = error.response.data.status_code;
    //       let message;
    //       switch (errorCode) {
    //         case 409:
    //           message = error.response.data.message;
    //           this.$notify({
    //             type: "error",
    //             title: "Failed",
    //             message
    //           });
    //           break;
    //         default:
    //           this.ehm__errorMessages(error, true);
    //       }
    //     }
    //   } finally {
    //     this.loading = false;
    //   }
    // },
  }
};
</script>

<style>
.dark-tag {
  background-color: #0a2463 !important;
  border-color: #0a2463 !important;
  width: 25%;
  height: 24px;
  padding: 0px 3px 0px 9px;
  margin-right: 5px;
  float: left;
  border-radius: 20px;
  font-size: 10px;
}

.light-tag {
  background-color: #eff1f5 !important;
  border-color: #eff1f5 !important;
  width: 25%;
  height: 24px;
  padding: 0px 3px 0px 9px;
  float: left;
  border-radius: 20px;
  font-size: 10px;
  color: #050e18 !important ;
}
.light-tag a {
  color: #050e18 !important;
}

.dark-tag1 {
  background-color: #0a2463 !important;
  border-color: #0a2463 !important;
  width: 30%;
  height: 25px;
  padding: 0px 0px 0px 7px;
  float: left;
  border-radius: 20px;
  font-size: 10px;
  color: #fff !important;
  margin-right: 5px;
  line-height: 24px !important;
}
.light-tag1 {
  background-color: #eff1f5 !important;
  border-color: #eff1f5 !important;
  width: 30%;
  height: 25px;
  padding: 0px 0px 0px 7px;
  float: left;
  border-radius: 20px;
  font-size: 10px;
  color: #050e18 !important;
  line-height: 24px !important;
}

.dark-tag2 {
  background-color: #0a2463 !important;
  border-color: #0a2463 !important;
  width: 37%;
  height: 25px;
  padding: 2px 0px 0px 6px;
  float: left;
  border-radius: 20px;
  font-size: 10px;
  color: #fff !important;
  margin-right: 5px;
}
.light-tag2 {
  background-color: #eff1f5 !important;
  border-color: #eff1f5 !important;
  width: 37%;
  height: 25px;
  padding: 2px 0px 0px 6px;
  float: left;
  border-radius: 20px;
  font-size: 10px;
  color: #050e18 !important;
}
</style>
